<template>
	<div class="withdrawalPlan">
    <rxNavBar title="提现进度"></rxNavBar>
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<div class="Wallet">
				<div class="detail" v-for="item in applyList">
					<div class="detail_border">
						<div>
							<div class="detail_message">提现{{item.applicationMoney}}元</div>
							<div class="cause" v-show="item.appliactionStatus=='1'">{{item.applicationRemark}}</div>
							<div class="reson" v-show="item.appliactionStatus=='2'"> 原因:{{reson}}</div>
							<div class="detail_titme">{{item.applicationTime}}</div>
						</div>
						<div class="detail_money orangeColor" v-if="item.appliactionStatus=='2'">
							未通过
						</div>
						<div class="detail_money" v-else-if="item.appliactionStatus=='1'">
							{{-item.applicationMoney}}
						</div>
						<div class="detail_money orangeColor" v-else>
							申请中
						</div>

						<!-- <div class="detail_money" :class="{'orangeColor':item.appliactionStatus=='1'}">
                            {{item.applicationMoney}}
                        </div> -->
					</div>
				</div>
			</div>
			<!--空状态显示-->
			<common-empty v-if="isEmptuyFlag"></common-empty>
		</van-pull-refresh>
	</div>
</template>

<script>
	import {
		NavBar,
		Icon,
		Cell,
		CellGroup,
		Button,
		Col,
		Row,
		List,
		PullRefresh,
		Toast
	} from 'vant';
	import {
		withdrawalApplicationList
	} from "../../../getData/getData";
  import {
    getStaffId, globaluserId,
    responseUtil
  } from "../../../libs/rongxunUtil";
  import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	export default {
		components: {
			[NavBar.name]: NavBar,
			[Icon.name]: Icon,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Button.name]: Button,
			[Col.name]: Col,
			[Row.name]: Row,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			[Toast.name]: Toast,
      rxNavBar
		},
		data() {
			return {
				reson: '提现金额超过余额',
				applyList: [],
				isLoading: false,
				isEmptuyFlag: false,
			}
		},
		name: "withdrawalPlan",
		mounted() {
			this.getList()
		},
		methods: {
			//返回上一层
			leftReturn() {
				this.$router.go(-1);
			},

			getList() {
				let that = this;
				let initData = {
					user_id: globaluserId(),
				};
				withdrawalApplicationList(initData).then(response => {
					responseUtil.dealResponse(that, response, () => {
						that.applyList = response.data.data.withdrawalApplicationList
						if (response.data.data.withdrawalApplicationList == undefined || response.data.data.withdrawalApplicationList.length == 0) {
							that.isEmptuyFlag = true
						} else {
							that.isEmptuyFlag = false
						}
					});
				});
			},
			onRefresh() {
				let that = this
				that.getList()
				setTimeout(() => {
					Toast('刷新成功');
					that.isLoading = false;
				}, 1000);

			},
		},
	}
</script>

<style scoped>
	.orangeColor {
		color: #FF5D3B;
	}

	.Wallet {
		background-color: #FFFFFF;
		width: 375px;

	}

	.border {
		display: flex;
	}

	.detail_border {
		display: flex;
		border-bottom: 1px solid #EFEFEF;

	}

	.detail {
		padding: 10px 15px 0px 15px;
		width: 345px;
	}

	.detail_message {
		font-size: 14px;
		font-weight: bold;
		white-space: nowrap;
		width: 154px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.cause {
		padding-top: 5px;
		font-size: 13px;
		font-weight: normal;
		color: #999999;
	}

	.detail_titme {
		padding-top: 5px;
		padding-bottom: 10px;
		font-size: 13px;
		font-weight: normal;
		color: #999999;
	}

	.reson {
		padding-top: 3px;
		padding-bottom: 3px;
		font-size: 13px;
		font-weight: normal;
		color: #999999;
	}

	.detail_money {
		flex: 1;
		text-align: right;
		font-size: 14px;
		font-weight: bold;
	}

	.noMessage {
		font-size: 13px;
		font-weight: normal;
		color: #999999;
		text-align: center;
		padding-top: 20px;
	}
</style>
